.NewsListPage {
  .Logo {
    position: fixed;
    top: 0;
    height: 10%;
    width: 100vw;
    z-index: 2;
    background-color: black;
  }
  h1 {
    color: white;
    width: 75%;
    margin: 100px auto;
    font-weight: 300;
    font-size: 48px;
    font-family: 'Inter', sans-serif;
  }
  .NewsContainer {
    width: 75%;
    margin: 100px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    font-family: 'Noto Sans JP';
  }
  .NewsCard {
    margin-bottom: 100px;
  }
}

figure, img {
  margin: 0;
  max-width: 100%;
  height: auto;
}
.HTMLContent {
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  font-family: 'Noto Sans JP';
}