.Logo {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  font-weight: 700;
  color: white;
  cursor: pointer;
  overflow: hidden;
  img {
    width: 260px;
  }
}