.NewsPage {
  color: white;
  font-family: "Noto Sans JP";
  min-height: 100vh;
  .Logo {
    position: fixed;
    top: 0;
    height: 10%;
    width: 100vw;
    z-index: 2;
    background-color: black;
  }
  .NewsContainer {
    opacity: 1;
    animation: fadeInOpacity 1000ms linear 1;
    width: 75%;
    margin: 120px auto;
    overflow-x: hidden;
    .NewsLink {
      font-weight: 300;
      font-size: 48px;
      font-family: "Inter", sans-serif;
      margin-bottom: 55px;
      cursor: pointer;
      width: fit-content;
    }
    .FeaturedImage {
      width: 100%;
      height: 550px;
      @media only screen and (max-width: 950px) {
        height: 300px;
      }
    }
    .Title {
      font-weight: 500;
      font-size: 36px;
      line-height: 54px;
    }
    .Date {
      font-size: 20px;
    }
    .Content {
      width: 75%;
      margin: 40px auto;
      @media only screen and (max-width: 950px) {
        width: 100%;
      }
    }
    @media only screen and (max-width: 950px) {
      width: 85%;
    }
  }
}
