.Timeline {
  height: 100vh;
  width: 100%;
  margin-bottom: 50px;
  h2 {
    font-weight: 300;
    font-size: 48px;
    line-height: 58px;
    font-family: 'Inter', sans-serif;
  }
  .StepsContainer {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 800px;
    max-width: 600px;
    margin: 0 auto;
    @keyframes fadeIn {
      from {opacity: 0}
      to {opacity: 1}
    }
    .StepOne {
      top: 0;
      left: calc(50% - 100px);
      background-color: #303940;
      animation-delay: .3s;
      color: white;
    }
    .ArrowOne {
      transform: rotate(210deg);
      position: absolute;
      top: 22%;
      left: 66%;
      animation-delay: .6s;
      @media only screen and (max-width: 950px) {
        top: 25%;
      }
    }
    .StepTwo {
      top: 25%;
      left: calc(90% - 100px);
      background-color: #434F59;
      animation-delay: .9s;
      color: white;
      @media only screen and (max-width: 950px) {
        top: 29%;
      }
    }
    .ArrowTwo {
      transform: rotate(290deg);
      position: absolute;
      top: 55%;
      left: 78%;
      animation-delay: 1.2s;
      @media only screen and (max-width: 950px) {
        top: 54%;
      }
    }
    .StepThree {
      top: 65%;
      left: calc(75% - 100px);
      background-color: #8FAABF;
      animation-delay: 1.5s;
      @media only screen and (max-width: 950px) {
        top: 58%;
      }
    }
    .ArrowThree {
      position: absolute;
      top: 75%;
      left: 47%;
      animation-delay: 1.8s;
      @media only screen and (max-width: 950px) {
        top: 83%;
      }
    }
    .StepFour {
      top: 65%;
      left: calc(25% - 100px);
      background-color: #A2C1D9;
      animation-delay: 2.1s;
      @media only screen and (max-width: 950px) {
        top: 87%;
      }
    }
    .ArrowFour {
      transform: rotate(70deg);
      position: absolute;
      top: 55%;
      left: 12%;
      animation-delay: 2.4s;
      @media only screen and (max-width: 950px) {
        top: 112%;
      }
    }
    .StepFive {
      top: 25%;
      left: calc(10% - 100px);
      background-color: #B5D8F2;
      animation-delay: 2.7s;
      @media only screen and (max-width: 950px) {
        top: 116%;
      }
    }
    .StepOne, .StepTwo, .StepThree, .StepFour, .StepFive {
      font-family: "Noto Sans JP";
      position: absolute;
      font-size: 14px;
      line-height: 20px;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 37px;
      animation-fill-mode: forwards;
      animation-name: fadeIn;
      animation-duration: 1s;
      opacity: 0;
      .StepTitle {
      font-size: 24px;
      margin-bottom: 7px;
      }
      @media only screen and (max-width: 950px) {
        left: calc(50% - 100px);
      }
    }
    .ArrowOne, .ArrowTwo, .ArrowThree, .ArrowFour, .ArrowFive {
      width: 45px;
      animation-fill-mode: forwards;
      opacity: 0;
      animation-name: fadeIn;
      animation-duration: 1s;
      @media only screen and (max-width: 950px) {
        width: 30px;
        transform: rotate(270deg);
        left: calc(50% - 15px);
      }
    }
  }
}