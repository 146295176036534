.ErrorMessage {
  width: 100vw;
  height: 100vh;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Inter", sans-serif;
}

.ErrorMessage > h2 {
  font-size: 3rem;
  margin-bottom: 0;
}

.ErrorMessage > p {
  font-size: 1rem;
  text-transform: uppercase;
  margin-top: 0;
}

.ErrorMessage > a {
  color: white;
  font-size: 1rem;
  text-transform: uppercase;
  margin-top: 2em;
  text-decoration: none;
  padding: 0.4em;
  border-radius: 3px;
  border: 1px solid #fff;
  transition: all 200ms linear;
}

.ErrorMessage > a:hover {
  background: #fff;
  color: #000;
}

.ErrorMessage > a::after {
  content: "↩";
  font-size: 1.2rem;
  margin-left: 4px;
}
