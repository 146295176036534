.NewsSection {
  margin-top: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  @media only screen and (max-width: 950px) {
    margin-top: 40px;
  }
  h2, h3, .ReadMore {
    font-family: 'Inter', sans-serif;
    width: 75%;
    @media only screen and (max-width: 950px) {
      width: 85%;
    }
  }
  .ReadMore {
    margin-top: 40px;
    cursor: pointer;
  }
  h2 {
    margin-bottom: 50px;
    font-weight: 300;
    font-size: 48px;
  }
  .NewsContainer {
    width: 75%;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 950px) {
     flex-direction: column;
      width: 85%;
    }
  }
}