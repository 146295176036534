.Footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 30px;
  .Description {
    color: white;
    font-size: 14px;
    line-height: 21px;
    font-family: "Noto Sans JP";
  }
  @media only screen and (max-width: 950px) {
    flex-direction: column;
    flex-flow: column-reverse;
  }
  .CTCLogo {
    width: auto;
  }
}