.FeaturedProjectSection {
  position: relative;
  padding: 170px 20%;
  margin-top: 200px;
  width: 100%;
  overflow: hidden;
  font-family: "Noto Sans JP";
  @media only screen and (max-width: 950px) {
    padding: 100px 10%;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 170vh;
    height: 1400px;
    background-color: #687d8b;
    border-radius: 50%;
    transform-origin: bottom;
    transform: translateX(-50%) translateY(100%) scale(2);
    z-index: -1;
    @media only screen and (max-width: 950px) {
      height: 1800px;
    }
  }
  .Content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    line-height: 30px;
    h2 {
      font-weight: 300;
      font-size: 48px;
      line-height: 58px;
      font-family: "Inter", sans-serif;
      span {
        font-size: 120px;
      }
      @media only screen and (max-width: 950px) {
        font-size: 38px;
        span {
          font-size: 65px;
        }
      }
    }
    .Description {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
    }
    .AsahiyakiLogo {
      width: 250px;
      margin: 100px 0 70px;
    }
    .Main {
      width: 100%;
      display: flex;
      justify-content: space-between;
      div {
        display: flex;
        flex-direction: column;
      }
      .AsahiyakiOne {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        span {
          margin-top: 20px;
        }
      }
      .AsahiyakiTwo {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        .AsahiyakiTwoDescription {
          margin-top: 20px;
        }
        .AsahiyakiTwoName {
          text-align: right;
        }
      }
      .AsahiyakiOne {
        width: 400px;
        @media only screen and (max-width: 950px) {
          width: 100%;
        }
      }
      .AsahiyakiTwo {
        width: 250px;
        @media only screen and (max-width: 950px) {
          margin-top: 40px;
        }
      }
      @media only screen and (max-width: 950px) {
        flex-direction: column;
      }
    }
    .Timeline {
      margin-top: 100px;
      max-height: 900px;
      @media only screen and (max-width: 950px) {
        margin-top: 40px;
      }
    }
    .Support {
      margin-top: 170px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .SupportedBy {
        font-size: 18px;
        text-decoration-line: underline;
      }
      .QosmoLogo {
        width: 200px;
        height: 62px;
        transform: translateY(-22px);
      }
      .QosmoDescription {
        width: 400px;
        font-size: 16px;
        line-height: 24px;
      }
      @media only screen and (max-width: 1340px) {
        margin-top: 90px;
      }
      @media only screen and (max-width: 950px) {
        margin-top: 350px;
        flex-direction: column;
        .QosmoDescription {
          font-size: 12px;
          width: 100%;
          line-height: 20px;
        }
        .QosmoLogo {
          transform: translateY(0) translateX(-20px);
        }
        .SupportedBy {
          font-size: 16px;
          text-decoration-line: underline;
        }
      }
    }
  }
}
