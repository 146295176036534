.TechSection {
  margin: 200px 0 300px;
  display: flex;
  justify-content: center;
  color: white;
  font-size: 20px;
  line-height: 30px;
  .Content {
    width: 75%;
    display: flex;
    justify-content: space-between;
    h2 {
      margin: 0 0 80px;
      font-weight: 300;
      font-size: 40px;
      font-family: 'Inter', sans-serif;
      line-height: 45px;
      @media only screen and (max-width: 950px) {
        margin: 0 0 40px;
        line-height: 50px;
      }
    }
    p {
      margin-bottom: 26px;
    }
    .Descriptions, .Images {
      width: 48%;
      font-family: "Noto Sans JP";
    }
    .Image {
      margin-bottom: 100px;
      font-size: 14px;
      img {
        width: 100%;
      }
      @media only screen and (max-width: 950px) {
        margin-bottom: 50px;
      }
    }
    @media only screen and (max-width: 950px) {
      width: 85%;
      flex-direction: column;
      .Descriptions, .Images {
        width: 100%;
      }
    }
  }
  @media only screen and (max-width: 950px) {
    margin: 100px 0 50px;
  }
}