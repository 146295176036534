.NewsCard {
  width: 100%;
  max-width: 500px;
  cursor: pointer;
  color: white;
  @media only screen and (max-width: 950px) {
    flex-direction: column;
    margin-bottom: 40px;
   }
  .NewsCardTitle {
    margin-top: 20px;
    font-family: "Noto Sans JP";
  }
  .NewsCardDate {
    margin-top: 5px;
  }
  .Excerpt {
    margin-top: 20px;
  }
  .Image {
    width: 90%;
    height: 280px;
  }
}