.HeroSection {
  height: 100vh;
  .Logo {
    position: fixed;
    height: 10%;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    background-color: black;
  }
  .LogoSpace {
    height: 10%;
    background-color: black;
  }
  .Video {
    height: 80%;
    background-size: cover;
    z-index: -1;
    video {
      width: 100vw;
      height: 100vh;
      position: absolute;
      object-fit: cover;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
    .VolumeToggle {
      cursor: pointer;
      position: absolute;
      bottom: 11%;
      right: 10px;
      img {
        width: 35px;
      }
    }
  }
  .ScrollDownContainer {
    background-color: black;
    height: 11%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    img {
    width: 40px;
    }
  }
}