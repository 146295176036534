.ProjectDescriptionSection {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  line-height: 36px;
  font-family: "Noto Sans JP";
  .ParagraphsContainer {
    width: 75%;
    .FirstParagraph {
      margin-bottom: 80px;
    }
    .SecondParagraph {
      margin-bottom: 150px;
      @media only screen and (max-width: 950px) {
        margin-bottom: 100px;
      }
    }
    .ThirdParagraph {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 400px;
      }
      span {
        width: 50%;
        padding: 20px;
        font-size: 20px;
      }
      @media only screen and (max-width: 950px) {
        flex-direction: column;
        span {
          width: 100%;
          display: block;
        }
        img {
          width: 300px;
        }
      }
    }
    @media only screen and (max-width: 950px) {
      width: 85%;
    }
  }
}